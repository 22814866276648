import React from 'react';
import { guid } from '../../shared/lib/utils';
import {
    paragraph,
    paragraph2,
    paragraph3,
    randomString,
} from '../prototypeData';
import {Collapse, ICollapse } from '../components/Collapse';

const collapseGroups = [
    {
        title: randomString(6),
        collapses: [
            {
                title: randomString(8) + '. ' + randomString(3) + '?',
                text: `<p>${paragraph}</p>`,
            },
            {
                title: randomString(6) + '. ' + randomString(4) + '?',
                text: `<p>${randomString(20)}</p>
                <p>${randomString(12)}</p>`,
            },
            {
                title: randomString(8) + '?',
                text: `<p>${paragraph2}</p>`,
            },
        ] as Array<ICollapse>,
    },
    {
        title: randomString(18),
        collapses: [
            {
                title: randomString(6),
                text: `<p>${paragraph3}</p>`,
            },
            {
                title: randomString(12) + '. ' + randomString(18) + '?',
                text: `<p>${randomString(20)}</p>
                <p>${randomString(12)}</p>`,
            },
            {
                title: randomString(7),
                text: `<p>${paragraph}</p>`,
            },
        ] as Array<ICollapse>,
    },
    {
        title: randomString(12),
        collapses: [
            {
                title: randomString(6),
                text: `<p>${paragraph2}</p>`,
            },
            {
                title: randomString(12),
                text: `<p>${randomString(20)}</p>
                <p>${randomString(12)}</p>`,
            },
            {
                title: randomString(7),
                text: `<p>${paragraph}</p>`,
            },
        ] as Array<ICollapse>,
    },
];

const FAQPage = () => {
    return (
        <article>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-xl-8">
                        <br />
                        <br />
                        <br />
                        <h1>OFTE STILTE SPØRSMÅL OM KS KOMPLETT</h1>
                        <p className="lead">{randomString(30)}</p>
                        {collapseGroups.length &&
                            collapseGroups.map((group) => {
                                return (
                                    <div
                                        className="collapse-group"
                                        key={guid()}
                                    >
                                        <h2>{group.title}</h2>
                                        {group.collapses.length &&
                                            group.collapses.map((collapse) => {
                                                return (
                                                    <Collapse
                                                        {...collapse}
                                                        key={guid()}
                                                    />
                                                );
                                            })}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default FAQPage;
